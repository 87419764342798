<template>
  <div id="main-content">
    <Create
      :species="species"
      :visible="createVisible"
      :seq="seq"
      @ok="onCreated"
      @cancel="() => createVisible = false"
    />
    <Update
      :visible="updateVisible"
      :seq="seq"
      @ok="onUpdated"
      @cancel="() => updateVisible = false"
    />
    <a-row :gutter="24">
      <a-col :span="4">
        <DFSAdminNavbar active="" />
      </a-col>
      <a-col :span="20">
        <div class="admin-content">
          <div class="ops">
            <a-button type="primary" @click="onCreate">新建</a-button>
          </div>
          <a-table
            :loading="loading"
            :columns="columns"
            :data-source="seqs"
            :pagination="false"
            :row-key="record => record.slug"
          >
            <span slot="note" slot-scope="text">
              <template v-if="text">
                {{ text }}
              </template>
              <template v-else>
                暂无
              </template>
            </span>
            <span slot="created-time" slot-scope="text">
              {{ text | moment }}
            </span>
            <template slot="action" slot-scope="text, record">
              <a @click="onUpdate(record)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm
                title="你确定删除这条序列吗？"
                @confirm="onDestroy(record.slug)"
              >
                <a>删除</a>
              </a-popconfirm>
            </template>
          </a-table>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DFSAdminNavbar from '@/components/DFSAdminNavbar';
import Create from './Create.vue';
import Update from './Update';

import { columns } from './utils';
import { destroy, list } from '@/services/seq';

export default {
  name: 'Content',
  components: {
    DFSAdminNavbar,
    Create,
    Update,
  },
  data() {
    return {
      species: this.$route.params.species,
      columns,
      loading: false,
      seqs: [],
      seq: {},
      createVisible: false,
      updateVisible: false,
    };
  },
  methods: {
    fetch() {
      this.loading = true;
      list(this.species).then(({ data }) => {
        this.loading = false;
        this.seqs = data.data;
      });
    },
    onCreate() {
      this.createVisible = true;
    },
    onCreated() {
      this.createVisible = false;
      this.fetch();
    },
    onDestroy(slug) {
      destroy(slug).then(() => {
        this.$message.info(`${slug}删除成功。`)
        this.fetch();
      });
      console.log(slug);
    },
    onUpdate(seq) {
      this.seq = seq;
      this.updateVisible = true;
    },
    onUpdated() {
      this.updateVisible = false;
      this.fetch();
    }
  },
  mounted() {
    this.fetch();
  }
}
</script>


<style lang="stylus" scoped>
#main-content
  padding-top: 2rem
  .ops
    margin-bottom: 1rem
</style>
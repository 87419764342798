<template>
  <a-modal
    title="新建序列"
    :visible="visible"
    :width="800"
    @ok="onOk"
    @cancel="onCancel"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="序列" prop="text">
        <a-textarea :rows="20" v-model="form.text" placeholder="请输入序列" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="note">
        <a-input v-model="form.note" placeholder="请输入备注" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { create } from '@/services/seq';
import { rules } from './utils';

export default {
  name: 'Create',
  props: {
    species: String,
    visible: Boolean
  },
  data() {
    return {
      rules,
      form: {
        text: '',
        note: ''
      }
    };
  },
  methods: {
    onOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const seq = {
            species: this.species,
            text: this.form.text,
            note: this.form.note
          };
          create(seq).then(() => {
            this.$emit('ok');
          });
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$emit('cancel');
    }
  }
}
</script>

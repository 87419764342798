<template>
  <a-modal
    title="编辑序列"
    :width="800"
    :visible="visible"
    @ok="onOk"
    @cancel="onCancel"
  >
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="编号" prop="slug">
        <a-input v-model="form.slug" disabled />
      </a-form-model-item>
      <a-form-model-item label="序列" prop="text">
        <a-textarea v-model="form.text" :rows="20" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="note">
        <a-input v-model="form.note" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { update } from '@/services/seq';
import { rules } from './utils';

export default {
  name: 'Update',
  props: {
    visible: Boolean,
    seq: Object
  },
  data() {
    return {
      rules,
      form: {
        slug: '',
        text: '',
        note: ''
      }
    };
  },
  methods: {
    onOk() {
      this.$refs.form.validate(valid => {
        if (valid) {
          update(this.form).then(() => {
            this.$emit('ok');
          });
        } else {
          return false;
        }
      });
    },
    onCancel() {
      this.$emit('cancel');
    }
  },
  watch: {
    seq(value) {
      this.form = {
        slug: value.slug,
        text: value.text,
        note: value.note
      }
    }
  }
}
</script>
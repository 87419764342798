export const columns = [
  { title: '编号', dataIndex: 'slug' },
  {
    title: '备注',
    dataIndex: 'note',
    scopedSlots: {
      customRender: 'note'
    }
  },
  {
    title: '创建日期',
    dataIndex: 'created_time',
    scopedSlots: {
      customRender: 'created-time'
    }
  },
  {
    title: '操作',
    scopedSlots: {
      customRender: 'action'
    }
  }
];

const bases = new Set([
  'A', 'B', 'C', 'D', 'G', 'H',
  'K', 'M', 'N', 'R', 'S', 'T',
  'U', 'V', 'W', 'Y'
]);

function check(rule, value, callback) {
  value = value.trim().split(/\s/).join('').toUpperCase();
  if (value === '') {
    callback(new Error('请指定序列'));
  }
  for (let base of value) {
    if (! bases.has(base)) {
      callback(new Error(`序列中含有非法碱基：${base}`));
    }
  }
  callback();
}

export const rules = {
  text: [
    { required: true, message: '请输入序列', trigger: 'blur' },
    { validator: check, trigger: 'change' },
  ]
};